.b-table {
  display: table;
  width: 100%;

  &_row {
    display: table-row;

    &--hovered {
      &:hover {
        cursor: pointer;
        background-color: #f6f6f6;
      }
    }

    &--emergency {
      background-color: $red-emergency;
    }
  }

  &_cell {
    display: table-cell;
    padding: 10px;

    &--flex {
      display: flex;
    }
  }

  &_header {
    font-size: 16px;
    &--gray {
      color: $light-gray;
    }
  }
}
