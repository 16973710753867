input,
textarea {
  line-height: 1.14;
  font-family: inherit;
}

body {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

#app {
  height: 100%;
}

.b-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 10px;

  &_red {
    border: 1px solid $main-red;
    background-color: $main-red;
  }

  &_gray {
    border: 1px solid $light-gray;
    background-color: $light-gray;
  }

  &_white {
    border: 1px solid $light-gray;
    background-color: white;
  }

  &_orange {
    border: 1px solid $orange;
    background-color: $orange;
  }

  &_green {
    border: 1px solid $green;
    background-color: $green;
  }

  &_blue {
    border: 1px solid $blue;
    background-color: $blue;
  }

  &_yellow {
    border: 1px solid $yellow;
    background-color: $yellow;
  }
}

.b-content {
  display: flex;
  flex-direction: row;
}

.balloon {
  &-wrapper {
    position: absolute;
    background: #ffffff;
    max-width: 216px;
    max-height: 258px;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  &-content {
    height: 100%;
  }

  &-main {
    position: relative;
    padding: 12px 12px 0;
    font-size: 13px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 210px;

    &-top {
      position: relative;
    }

    &__name {
      font-size: 16px;
      margin-bottom: 6px;
      font-weight: 600;
      padding-right: 10px;
      &:hover {
        text-decoration: underline;
      }
    }

    &__status {
      display: flex;
      justify-content: space-between;
      margin: 26px 0 16px;
    }

    &__row {
      box-shadow: inset 0 1px 0 0 rgba(37, 45, 58, 0.06);
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      width: 100%;

      span {
        flex-shrink: 0;
        //word-break: break-all;

        &:first-child {
          max-width: 130px;
          color: rgba(37, 45, 58, 0.3);
        }
      }
    }
  }

  &-bottom {
    padding: 16px;
    box-shadow: inset 0 1px 0 0 rgba(37, 45, 58, 0.06);
    display: flex;
    justify-content: space-between;

    button {
      background-color: #ffffff;
      outline: none;
      border: none;
      cursor: pointer;
      font-size: 13px;
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      color: #3498db;

      &:hover {
        path {
          fill-opacity: 0.5;
        }
      }
    }

    &__block {
      display: flex;

      button {
        &:first-child {
          margin-right: 13px;
        }
      }

      svg {
        margin-right: 4px;
      }
    }
  }

  &-circle {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;

    &:hover[data-hasdate="true"] {
      animation: none;
      &:after,
      &:before {
        display: flex;
      }
    }

    &:after {
      content: attr(data-date);
      display: none;
      height: 26px;
      width: 180px;
      background: rgba(0, 0, 0, 0.75);
      position: absolute;
      top: -8px;
      left: -190px;
      align-items: center;
      justify-content: center;
      color: white;
    }

    &:before {
      content: "";
      display: none;
      position: absolute;
      left: -10px;
      bottom: 0px;
      border: 5px solid transparent;
      border-left: 5px solid rgba(0, 0, 0, 0.75);
    }

    &__green {
      background-color: $green;
    }

    &__gray {
      background-color: rgba(37, 45, 58, 0.2);
    }

    &__blue {
      background-color: #4378ce;
    }

    &__orange {
      background-color: #f5a623;
      animation: mapCircle 1s linear infinite;
    }

    &__red {
      background-color: #fa4646;
      animation: mapCircle 1s linear infinite;
    }

    &__white {
      background-color: #ffffff;
      animation: mapCircle 1s linear infinite;
    }
  }

  &-menu {
    position: absolute;
    left: calc(100% - 32px);
    top: calc(100% - 16px);
    background-color: #ffffff;
    padding: 8px 0;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

    &__active {
      path {
        fill-opacity: 1;
      }
    }

    & > div {
      cursor: pointer;
      font-size: 13px;
      line-height: 1.1;
      white-space: nowrap;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      color: #3498db;

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }

      &:hover {
        background-color: #f0f3f7;
      }
    }
  }
}

@keyframes mapCircle {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cluster-content {
  position: absolute;
  background-color: #ffffff;
  font-size: 11px;
  border-radius: 3px;
  padding: 3px;
  bottom: -13px;
  left: 0;
  right: 0;
  margin: auto;
  height: 16px;
  line-height: 10px;
  font-weight: 600;
  border: 1px solid rgba(37, 45, 58, 0.1);
}

.table-track-vertical,
.filter-track-vertical {
  z-index: 99;
  position: absolute;
  width: 6px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
}
.table-track-vertical,
.filter-track-vertical,
.table-track-horizontal {
  div {
    background-color: #92969d !important;
  }
}

.table-track {
  &-vertical {
    right: 0;
  }
  &-horizontal {
    z-index: 99;
    position: absolute;
    height: 6px;
    left: 2px;
    right: 2px;
    border-radius: 3px;
    bottom: 0;
  }
}

.filter-track-vertical {
  right: 2px;
  &__group {
    z-index: 100;
  }
}

.table-view {
  display: flex;
  align-items: flex-start;
}

.orange-tooltip {
  background-color: #f5a623 !important;
  opacity: 1 !important;
  font-size: 12px !important;
  padding: 6px;

  &:after,
  &:before {
    display: none;
  }
}

.modal-view {
  position: relative !important;
}

#userTooltip {
  background-color: rgba(37, 45, 58, 0.9);
  opacity: 1 !important;
  font-size: 12px !important;
  padding: 6px;
  margin-top: 5px;
  margin-left: -85px;

  a {
    border-bottom: 1px dashed #ffffff;
    cursor: pointer;
    text-decoration: none;
  }

  &:after,
  &:before {
    display: none;
  }

  a,
  a:visited,
  a:active {
    color: #ffffff;
  }
}

iframe {
  outline: none;
  border: none;
}
#blur-wrapper {
  display: none;
}
.ReactVirtualized__Grid__innerScrollContainer {
  overflow: unset !important;
}
.ReactVirtualized__Grid.ReactVirtualized__List {
  overflow: auto !important;
  outline: none !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

* {
  /* FF scrollbars */
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  scrollbar-width: thin;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.ant-picker {
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;

  &-input {
    width: auto !important;

    & > input {
      height: 32px !important;
      border-radius: 3px !important;
      box-shadow: inset 0 1px 3px 0 rgba(37, 45, 58, 0.08);
      border: solid 1px rgba(37, 45, 58, 0.06) !important;
      padding: 8px 24px 8px 8px !important;
      width: 117px !important;
      background: url("../icons/IconSelectArrow.svg") no-repeat !important;
      background-position: right 5px top 6px !important;
      color: #252d3a !important;
      flex: unset !important;
      transition: unset !important;

      &:hover {
        border: solid 1px rgba(37, 45, 58, 0.16) !important;
      }
    }
  }

  &-focused {
    .ant-picker-input-active {
      & > input {
        padding: 7px 23px 7px 7px !important;
        border: solid 2px rgba(0, 97, 255, 0.5) !important;
        background-position: right 4px top 5px !important;

        &:focus {
          border-right-width: 2px !important;
        }
      }
    }
  }

  &-active-bar {
    display: none;
  }

  &-suffix {
    display: none;
  }
}

.ant-checkbox {
  &-group {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;

    & > label:last-child {
      margin-bottom: 0 !important;
    }
  }

  &-wrapper {
    margin-left: 0 !important;
    margin-bottom: 10px !important;
    color: #252d3a !important;
    font-family: inherit;
  }

  &-inner {
    border: 1px solid #d8d8d8 !important;
    transition: none !important;
  }

  &-checked {
    &::after {
      border: 1px solid #4982de !important;
      animation: unset !important;
      box-sizing: border-box;
    }

    .ant-checkbox-inner {
      background-color: #4982de !important;
      border: 1px solid #4982de !important;

      &::after {
        transition: none !important;
        box-sizing: border-box;
      }
    }
  }
}

.zone-dropdown {
  height: 29px;
  width: 95px;
  background: white;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.15),
    0 2px 5px -3px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  &-menu {
    padding: 3px;
    margin-top: 3px;
    background: white;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.15),
      0 2px 5px -3px rgba(0, 0, 0, 0.15);
    list-style-type: none;
  }
  &-item {
    padding: 0 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.ymap-checkbox {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  position: relative;
  margin-right: 8px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.true {
    border: 1px solid #4982de;
    background-color: #4982de;
  }
  &.false {
    border: 1px solid #d8d8d8;
    background-color: #ffffff;
  }

  svg {
    width: 10px;
    height: 10px;
  }
}

.am4chart-export-menu.export-main {
  text-indent: -13333337px;
  width: 30px;
  height: 30px;
  padding: 0;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAQCAYAAADNo/U5AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo4M2Q5NDllYS1lMjE3LTQ3Y2QtYTU1Ni04MTQ3NmRjNWEwYWQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RjJDMzE5OUFERDIwMTFFNEE3MUI4OTEyNEFENjEzQjQiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjJDMzE5OTlERDIwMTFFNEE3MUI4OTEyNEFENjEzQjQiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpiYjc1MTkyYi0yZTY4LTQ5YWUtYTgxOS01ZmM1OTI2YmVjNzYiIHN0UmVmOmRvY3VtZW50SUQ9ImFkb2JlOmRvY2lkOnBob3Rvc2hvcDoxODYwYzBkYy0xZDk5LTExNzgtYWI2Yi1lZWQ1ZWU2MWI5YmEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7MkIPxAAAAhUlEQVR42mIsKytjIBUwYRF7CMT/kfBDYjSRZdMg1FQCxO+gWAZNjQySHEgdAwtUogeIvwPxRCy2g/h8QJwPxFPRnQcSCAPiH2iafkDFp+Ly0zogdgPi91D+eyh/HaGAOAzEtkB8DEofRlfAgiOArgKxNVWDHGbTfxL0MFJkEyMpmgACDADF7RquHJcLdAAAAABJRU5ErkJggg==);
  background-color: #fff;
  background-position: center;
}

.scheme-mark {
  &-wrapper {
    position: absolute;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  &-content {
    height: 100%;
  }
}

.scheme-mark-hint {
  font-family: inherit;
  width: 280px;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 16px;
  box-sizing: border-box;
}
